import React from "react";
import { graphql } from "gatsby";
import { Pagination } from "../components/pagination";
import { Image } from "../components/image";
import { PageLink } from "../components/link";

const BlogPage = ({
  data,
  pageContext,
}: {
  pageContext: {
    limit: number;
    skip: number;
    prev?: string;
    next?: string;
  };
  data: {
    allSanityBlogpost: {
      edges: [];
    };
  };
}) => {
  return (
    <div className="max-w-6xl mx-auto px-4">
      <h1 className="text-center my-10 text-ocean lg:text-5xl">Blog</h1>
      <div className="flex flex-wrap">
        {data.allSanityBlogpost.edges.map((edge, i) => {
          const {
            featuredImg,
            title,
            date,
            author,
            slug,
          } = edge.node._rawContent.main;
          return (
            <div className="p-4 w-full md:w-1/2 lg:w-1/3" key={i}>
              <PageLink
                to={`/${slug.current}/`}
                className="h-full flex flex-col block bg-white border-2 border-violet p-4"
              >
                <Image
                  imageId={featuredImg.asset.id}
                  aspectRatio={1}
                  className="mb-4"
                />
                <div className="flex-grow flex justify-between flex-col">
                  <h4 className="text-ocean leading-tight mb-3">{title}</h4>
                  <div className="flex justify-between text-ocean">
                    <p>{author}</p>
                    <p>
                      {new Date(date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </p>
                  </div>
                </div>
              </PageLink>
            </div>
          );
        })}
      </div>
      <Pagination {...pageContext} />
    </div>
  );
};

export const query = graphql`
  query sanityBlogPostsQuery($skip: Int!, $limit: Int!) {
    allSanityBlogpost(
      limit: $limit
      skip: $skip
      sort: { fields: content___main___date, order: DESC }
    ) {
      edges {
        node {
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`;

export default BlogPage;
